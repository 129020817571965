import React from "react";

export const MeetingCard = ({ cardTitle, cardDesc, meetings, activated }) => {
  return (
    <div className="card p-4">
      <h6>{cardTitle}</h6>
      <h6 className="text-muted">{cardDesc}</h6>
      {meetings.map((meeting) => (
        <div className="form-check" key={meeting.id}>
          <input
            className="form-check-input"
            type="checkbox"
            id={meeting.id}
            checked={meeting.state === true}
            onChange={(e) => meeting.setState(e.target.checked ? true : false)}
            disabled={!activated}
          />
          <label className="form-check-label" htmlFor={meeting.id}>
            <small>{meeting.title}</small>
          </label>
        </div>
      ))}
    </div>
  );
};
