import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";
import { MeetingCard } from "./MeetingCard";

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  purchasedCongressDinner,
  congressDinner,
  gaSelected,
  physical,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedCongressDinner, setSelectedCongressDinner] = useState(null);
  const [selectedGaDivision, setSelectedGaDivision] = useState(false);
  const [selectedGaStandingCommittee, setSelectedGaStandingCommittee] =
    useState(false);
  const [selectedGaScienceBoard, setSelectedGaScienceBoard] = useState(false);
  const [selectedGaExecutiveCommittee, setSelectedGaExecutiveCommittee] =
    useState(false);
  const [selectedGaCouncil, setSelectedGaCouncil] = useState(false);
  const isAnyGaSelected = [
    selectedGaDivision,
    selectedGaStandingCommittee,
    selectedGaScienceBoard,
    selectedGaExecutiveCommittee,
    selectedGaCouncil,
  ].some((meeting) => meeting);

  const gaMeetingTypes = [
    {
      id: "ga_division",
      title: "Division Meeting",
      state: selectedGaDivision,
      setState: setSelectedGaDivision,
    },
    {
      id: "ga_standing_committee",
      title: "Standing Committee Meeting",
      state: selectedGaStandingCommittee,
      setState: setSelectedGaStandingCommittee,
    },
    {
      id: "ga_science_board",
      title: "Science Board Meeting",
      state: selectedGaScienceBoard,
      setState: setSelectedGaScienceBoard,
    },
    {
      id: "ga_executive_committee",
      title: "Executive Committee Meeting",
      state: selectedGaExecutiveCommittee,
      setState: setSelectedGaExecutiveCommittee,
    },
    {
      id: "ga_council",
      title: "Council Meeting",
      state: selectedGaCouncil,
      setState: setSelectedGaCouncil,
    },
  ];

  console.log(
    (!purchasedPhysical && selected === null && !isAnyGaSelected) ||
      (purchasedPhysical && selectedCongressDinner === null && !isAnyGaSelected)
  );

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedCongressDinner]}
        />

        {!gaSelected && (
          <>
            <input
              type="hidden"
              name="ga_division"
              value={selectedGaDivision}
            />

            <input
              type="hidden"
              name="ga_standing_committee"
              value={selectedGaStandingCommittee}
            />
            <input
              type="hidden"
              name="ga_science_board"
              value={selectedGaScienceBoard}
            />
            <input
              type="hidden"
              name="ga_executive_committee"
              value={selectedGaExecutiveCommittee}
            />
            <input type="hidden" name="ga_council" value={selectedGaCouncil} />
          </>
        )}
        {/* ------------ GA Meeting CARD ------------ */}
        {!gaSelected && (
          <>
            <MeetingCard
              cardTitle={"50th General Assembly 12-15 July 2025"}
              cardDesc={"(you can choose more than 1)"}
              meetings={gaMeetingTypes}
              activated={true}
            />
          </>
        )}

        <ProductCard
          cardTitle={"53rd WCC 14-19 July 2025"}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              setSelectedCongressDinner(null);
            }
          }}
          handleRadio={() => {
            // setSelectedCongressDinner(null);
          }}
        />

        {(selected || purchasedPhysical) && (
          <>
            <h4 className="text-center text-navy mt-4">
              Select Congress Dinner
            </h4>
            {/* ------------ congress dinner CARD ------------ */}
            <ProductCard
              cardTitle={"Congress Dinner Ticket"}
              products={congressDinner}
              selectedState={selectedCongressDinner}
              setSelectedState={setSelectedCongressDinner}
              activated={!purchasedCongressDinner}
              handleCheckbox={(e) => {
                if (!e.target.checked) {
                  setSelectedCongressDinner(null);
                }
              }}
              handleRadio={() => {
                // setSelectedCongressDinner(null);
              }}
            />
          </>
        )}

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}
        {/* for international delegate is compulsory to select one of the precongress */}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={
            // selected === null && selectedCongressDinner === null
            (!purchasedPhysical && selected === null && !isAnyGaSelected) ||
            (purchasedPhysical &&
              selectedCongressDinner === null &&
              !isAnyGaSelected)
          }
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
